import React from "react"
import { Link, withPrefix } from "gatsby"
import Layout from "../components/layouts/layout"
import HeadMeta from "../components/layouts/headmeta"
import ProgressBar from "../components/layouts/progressbar"
import { changeProgressBarOnScroll } from "../components/utils/index"

export default class DNAExplorerEssentialPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", function() {
      changeProgressBarOnScroll()
    })
    window.analytics.page("Static", "DNA Day")
  }

  render() {
    return (
      <Layout>
        <div id="dna-day-2020">
          <ProgressBar />
          <HeadMeta metadata={pageData.metadata} />
          <section
            className="hero hero-dna-day bg-gray"
            style={{ backgroundImage: `url(${pageData.hero_image})` }}
          >
            <div className="container">
              <div className="col-lg-8 offset-lg-2">
                <img
                  src={withPrefix("/img/dna-day/banner-context.png")}
                  alt="DNA Day"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </section>
          <section id="events" className="container mb-0 pb-3 pt-5">
            <h2 className="text-center">Learn More About DNA</h2>
            <div className="row d-flex align-items-center justify-content-center">
              <div className="row p-5">
                <div className="col-lg-4 offset-lg-2 text-center px-3">
                  <img
                    src={withPrefix("/img/dna-day/flash-card-post.png")}
                    alt="Flash Card"
                    style={{ width: "100%" }}
                  />
                  <a
                    className="btn btn-primary my-3"
                    href="https://www.facebook.com/advanxhealthmy/posts/920779215019177"
                    style={{ backgroundColor: "#4C81CB" }}
                  >
                    Find Out More
                  </a>
                </div>
                <div className="col-lg-4 text-center px-3">
                  <img
                    src={withPrefix("/img/dna-day/word-search-post.png")}
                    alt="Word Search"
                    style={{ width: "100%" }}
                  />
                  <a
                    className="btn btn-primary my-3"
                    href="https://www.facebook.com/advanxhealthmy/posts/921396968290735"
                    style={{ backgroundColor: "#4C81CB" }}
                  >
                    Find Out More
                  </a>
                </div>
                <div className="col-lg-4 offset-lg-2 text-center px-3">
                  <img
                    src={withPrefix("/img/dna-day/crossword-puzzle-post.png")}
                    alt="Crossword Puzzle"
                    style={{ width: "100%" }}
                  />
                  <a
                    className="btn btn-primary my-3"
                    href="https://www.facebook.com/advanxhealthmy/posts/921493844947714"
                    style={{ backgroundColor: "#4C81CB" }}
                  >
                    Find Out More
                  </a>
                </div>
                <div className="col-lg-4 text-center px-3">
                  <img
                    src={withPrefix("/img/dna-day/listicle-post.png")}
                    alt="Listicle"
                    style={{ width: "100%" }}
                  />
                  <a
                    className="btn btn-primary my-3"
                    href="https://bit.ly/3bB9NWN"
                    style={{ backgroundColor: "#4C81CB" }}
                  >
                    Find Out More
                  </a>
                </div>
              </div>
              <div className="p-5 text-center" id="cast-dash">
                <h4 className="mb-3">
                  Fun & Educational Games related to DNA and Genes
                </h4>
                <p>
                  Explore the world of science about DNA and genetics through
                  our CAST-Dash platform!
                  <br />
                  <br />
                  We have 10 interactive learning games from Legends of Learning
                  that will give you a fun learning experience~!
                </p>
                <a
                  className="btn btn-primary mt-2"
                  href="https://cast-dash.herokuapp.com/"
                  style={{ backgroundColor: "#4C81CB" }}
                >
                  Come and Play
                </a>
                <br />
                <small>(Only for PC Users)</small>
              </div>
            </div>
          </section>
          {/*<section id="promotion" className="promotion py-5" style={{backgroundColor: "rgba(244,241,235,0.5)"}}>
            <div className="container">
              <div className="row align-items-center my-2">
                <div className="col-md-10 offset-md-1 text-center">
                  <h2 className="text-center">Limited Time Promotion</h2>
                  <h4>Enjoy 40% OFF Pre-Orders For DNA Explorer Prime & Carrier Test!</h4>
                </div>
              </div>
              <div className="row align-items-center my-5">
                <div className="col-md-5 offset-md-1">
                  <div className={`card border-explorer-yellow`}>
                    <div className="card-body text-center">
                      <h3><span className="badge badge-warning">NEW</span></h3>
                      <img className="img-fluid px-md-5" src={withPrefix('img/logo/DNA-Explorer-Prime.png')} alt="DNA Explorer Prime"/>
                      <p>Here are all the reports that you need for better health. </p>
                      <br/>
                      <p>121 reports on: <br/>Nutrition, Diet, Fitness, Inner Potential + Skin, Health Risks + Allergy</p>
                      <br/>
                      <hr className="mx-5"/>
                      <div className="price-cta">
                        <div className="row align-items-center my-4">
                          <div className="col-7 text-right">
                            <h2>RM 959</h2>
                          </div>
                          <div className="col-5 text-left">
                            <del>RM1599</del>
                          </div>
                          <div className="col-12">
                            <p className="text-center">(Launch Price: RM 1299)</p>
                          </div>
                        </div>
                        <Link className="btn btn-explorer-yellow btn-block" to={ withPrefix("/products/dna-explorer-prime") }>LEARN MORE</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className={`card border-explorer-carrier-purple`}>
                    <div className="card-body text-center">
                      <h3><span className="badge badge-warning">NEW</span></h3>
                      <img className="img-fluid px-md-5" src={withPrefix('img/logo/DNA-Explorer-Carrier.png')} alt="DNA Explorer Prime"/>
                      <p>Planning to have a baby but worried that you may carry genetic mutations?</p>
                      <br/>
                      <p>Get 13 insightful reports on recessive genetic diseases with counselling from a Genetic Counsellor</p>
                      <br/>
                      <hr className="mx-5"/>
                      <div className="price-cta">
                        <div className="row align-items-center my-4">
                          <div className="col-7 text-right">
                            <h2>RM 779</h2>
                          </div>
                          <div className="col-5 text-left">
                            <del>RM1299</del>
                          </div>
                          <div className="col-12">
                            <p className="text-center">(Launch Price: RM 1099)</p>
                          </div>
                        </div>
                        <Link className="btn btn-explorer-carrier-purple btn-block" to={ withPrefix("/products/dna-explorer-carrier") }>LEARN MORE</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row align-items-center my-2">
                <div className="col-md-11 offset-md-1 text-center">
                  <p>*Terms and conditions apply.</p>
                  <p>**For existing customers, please PM us for upgrade options.</p>
                  <br/><br/>
                  <h3>Terms and Conditions</h3>
                  <div className="text-left mt-3">
                    <p>1. Pre-order starts on 26 April 2020 and ends on 3 May 2020.
                    <br/>2. This offer is valid for DNA Explorer Prime and DNA Explorer Carrier only.
                    <br/>3. This offer is valid for pre-orders only.
                    <br/>4. This offer is valid for new customers only.
                    <br/>5. Pre-ordered kits will be sent out by the end of May or in early June 2020.
                    <br/>6. Reports will be sent out within 45 - 60 days after you have returned the samples.
                    <br/>7. This promotion is not applicable with other discounts and promotions.
                    <br/>8. Advanx Health reserves the right to amend the Terms & Conditions of this promotion at any time without prior notice.</p>
                  </div>
                </div>
              </div>
            </div>
          </section>*/}
        </div>
      </Layout>
    )
  }
}

export const pageData = {
  metadata: {
    title: "DNA Day | Advanx Health",
    description: "DNA Day 2020 - Learn More About DNA with us.",
    image: "https://www.advanxhealth.com/img/share/dna-day-2020.png",
    url: "https://www.advanxhealth.com/dna-day",
  },
  hero_image: "/img/dna-day/banner-bg.png",
}
