export function changeProgressBarOnScroll() {
  let progressBar = document.getElementById('page-nav-progress-bar')
  let maxHeight = document.body.scrollHeight - 500;
  let currentHeight = document.documentElement.scrollTop;
  let percentageScroll = Math.ceil(currentHeight / maxHeight * 100) + 10;

  progressBar.style.width = percentageScroll + "%"
  progressBar.aria = { valuenow: percentageScroll }
}

export function cleanUpPathname(){
  var path = window.location.pathname.replace(/\.html/, "").replace(/\//, "")
  if( path === "" ){
    return "home"
  }else{
    return path
  }
}

export function getEmailFromUtmParams() {
  let match = document.URL.match(/email=([^&]+)/)
  let email = ""
  if (match) {
    email = decodeURIComponent( match[1] );
  }
  let iframe = document.getElementById('thank-you-iframe')
  if ((email.length > 0) && iframe) {
    let source = iframe.src
    iframe.src = source + email
  }
}

export function getCookie(cname) {
  var name = cname + "=";
  if(typeof document !== "undefined") {
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
  }
}

export function setCookie(cname, cvalue, exdays) {
  if(typeof document !== "undefined") {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
}

export function slugify(string) {
  return string.toString().toLowerCase()
    .replace(/\s+/g, '-')
}
